<template>
  <div class="app-foot">
    {{footerMsgCopyright}}
    <span v-html="footerMsgName">{{footerMsgName}}</span>
  </div>
</template>

<script>
export default {
  name: 'AppFoot',
  data() {
    return {
      // 版权说明的文字
      footerMsgCopyright: 'Copyright © 2016 zhifaba.com Inc.All Rights Reserved.',
      // 单位
      footerMsgName: '北京碧莲盛不剃发植发医疗美容门诊部有限责任公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">京ICP备12001479号-1</a>'
    }
  }
}
</script>

<style scoped>
.app-foot {
  /* footer 固定在页面底部 */
  min-height: 35px;
  background-color: #505c78;
  width: 100%;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>